
const images = {
    logo: require('./images/Let’s_take _a _trip _logo.png'),
    dashboard_light: require('./icons/dashboard_light2.png'),
    dashboard_gray: require('./icons/dashboard_gray.png'),
    hotel_light: require('./icons/hotel_light3.png'),
    visa: require('./icons/visa.png'),
    airplane: require('./icons/airplane.png'),
    trip: require('./icons/trip.png'),
    search_glass: require('./icons/magnifying-glass.png'),
    visa_app: require('./icons/visa app.png'),
    learnmore: require('./icons/learnmore.png'),
    study_lamp: require('./icons/study-lamp.png'),
    contact: require('./icons/contact.png'),
    plane_takeoff: require('./icons/plane_takeoff.png'),
    google: require('./icons/google.png'),
    facebook: require('./icons/facebook.png'),
    flying_plane: require('./icons/flying_plane.png'),
    search: require('./icons/search.png'),
    filter: require('./icons/filter.png'),
    image_search: require('./icons/Asset 7logos.png'),
    save: require('./icons/save.png'),
    bell: require('./icons/bell.png'),
    gift: require('./icons/offer-box.png'),
    more: require('./icons/more-btn.png'),
    refresh: require('./icons/circular-arrow.png'),
    passport_gray: require('./icons/passport_gray.png'),
    passport_light: require('./icons/passport_light.png'),
    hotel_light: require('./icons/hotel_light2.png'),
    arrow_right: require('./icons/arrow-right.png'),
    hotel_gray: require('./icons/hotel_gray.png'),
    inbox_gray: require('./icons/inbox_gray.png'),
    inbox_light: require('./icons/inbox_light.png'),
    settings: require('./icons/settings.png'),
    user_edit: require('./icons/image-user-edit.png'),
    logout: require('./icons/logout.png'),
    collapse: require('./icons/collapse.png'),
    like: require('./icons/like.png'),
    save: require('./icons/save.png'),
    location_white: require('./icons/location (2).png'),
    bubble: require('./icons/bubble.png'),
    star: require('./icons/star.png'),
    no_message: require('./icons/funny-messages.png'),
    twitter: require('./icons/twitter (1).png'),
    follow: require('./icons/follow (2).png'),
    instagram: require('./icons/instagram (1).png'),
    user: require('./icons/user.png'),
    user2: require('./icons/user (2).png'),
    loading: require('./icons/loading.png'),
    location: require('./icons/location.png'),
    calendar: require('./icons/calendar.png'),
    airplane_loading_gif: require('./images/Airplane_loading.gif'),
}


export { images }