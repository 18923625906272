export const newsObj = [
    {
        id: 1,
        header: 'Loved your trip?',
        details: 'Rate your lets take a trip experience',
        active: false,
        checked: false,
        favourite: false,
        archive: false,
        trash: false
    },
    {
        id: 2,
        header: 'Unlock your travel potential',
        details: 'Personalize your trip on LetsTakeATrip. Save time & money...',
        active: false,
        checked: false,
        favourite: false,
        archive: false,
        trash: false
    },
    {
        id: 3,
        header: 'Welcome to LetsTakeATrip',
        details: 'LetsTakeATrip makes travel planning effortless. Explore...',
        active: false,
        checked: false,
        favourite: false,
        archive: false,
        trash: false
    },
    {
        id: 4,
        header: 'Loved your trip?',
        details: 'Rate your lets take a trip experience',
        active: false,
        checked: false,
        favourite: false,
        archive: false,
        trash: false
    }
]